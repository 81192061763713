@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;500&display=swap);
.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #2c2d2d;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.w3ajs-close-btn {
  display: none !important;
}

.w3a-external-toggle {
  display: none !important;
}

#w3a-modal .w3a-group:not(.w3a-group--hidden):not(:last-child),
#w3a-modal .w3a-group:not(.w3a-group--social-hidden):not(:last-child),
#w3a-modal .w3a-group:not(.w3a-group--email-hidden):not(:last-child),
#w3a-modal .w3a-group:not(.w3a-group--ext-wallet-hidden):not(:last-child) {
  border-bottom: none !important;
}

body {
  display: flex;
  justify-content: center;
  align-content: center;
  background: #191b20;
  font-family: Poppins;
}
.banner {
  position: absolute;
  top: 30%;
  background: #242731;
  width: 328px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 16px;
  border-radius: 8px;
}
button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  border: none;
  background: #6c5dd3;
  color: #fff;
  border-radius: 6px;
  font-family: Poppins;
  margin-top: 12px;
}
button:hover {
  cursor: pointer;
}
button:focus {
  box-shadow: inset 2px 2px 3px #6c5dd3;
}
h1 {
  margin: 0;
  font-size: 20px;
  color: #fff;
  font-weight: 500;
}
p {
  margin: 0 0 8px 0;
  font-size: 14px;
  color: #a2a2a2;
}
.image {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 145px;
  height: 178px;
  border-radius: 0 6px 6px 0;
  /* background: #121212; */
  /* background-image: url(https://brine-assets-public.s3.ap-southeast-1.amazonaws.com/img/logo-sm-dark.png); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

